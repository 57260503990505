<template>
  <Popup
    :width="width"
    :margin="margin"
    :radiusType="radiusType"
    :closePopupEvent="closePopup"
  >
    <div class="app-share" slot="component">
      <div>
        <p class="app-share-link-title">
          {{ $t("share this app with friends") }}
        </p>
        <p class="Clipboard" v-if="copiedText">copied</p>
        <!-- <p class="app-share-app-title">Check out "{{ app.title }}" on {{ projectName }}!</p> -->
        <div class="app-share-input">
          <input
            class="app-share-input-link-field"
            id="app-share-link"
            type="text"
            @keydown.prevent
            v-model="linkTwo"
            ref="link"
          />
          <button
            @click="copyText"
            class="app-share-input-btn-copy copy-btn"
            :class="[
              providerUniqueId === 'z01x1wPo'
                ? 'primary-nammaflix-button'
                : 'primary-net5-button',
            ]"
            :data-clipboard-text="linkTwo"
          >
            {{ $t("copy") }}
          </button>
        </div>
      </div>
      <p class="app-share-title">{{ $t("share to") }}</p>

      <div class="social_icons">
          <ul  class="icon_list social_icons-about">
            <li class="icon" v-if="appConfig.providerDetails.social.facebook">
              <a tabindex="1"
                :href="appConfig.providerDetails.social.facebook"
                target="_blank"
                rel=”noreferrer”
                aria-label="facebook link"
              >
                <img
                  src="@/assets/icons/facebookicon.svg"
                  alt="facebook_img"
                />
              </a>
            </li>
            <li
              class="icon"
              v-if="appConfig.providerDetails.social.instagram"
            >
              <a tabindex="1"
                :href="appConfig.providerDetails.social.instagram"
                target="_blank"
                rel=”noreferrer”
                aria-label="instagram link"
              >
                <img
                  src="@/assets/icons/instagramicon.svg"
                  alt="instagram_img"
                  style="width: 20px; height: 20px"
                />
              </a>
            </li>
            <li class="icon" v-if="appConfig.providerDetails.social.twitter">
              <a tabindex="1"
                :href="appConfig.providerDetails.social.twitter"
                target="_blank"
                rel=”noreferrer”
                aria-label="twitter link"
              >
                <img src="@/assets/icons/twittericon.svg" alt="twitter_img" />
              </a>
            </li>

            <li class="icon" v-if="appConfig.providerDetails.social.youtube">
              <a tabindex="1"
                :href="appConfig.providerDetails.social.youtube"
                target="_blank"
                rel=”noreferrer”
                aria-label="pinterest link"
              >
                <img src="@/assets/icons/Youtubeicon.svg" alt="youtube" />
              </a>
            </li>
          </ul>
       </div>
      <!-- <div>
        <ul class="app-share-list">
          <li>
            <a
              :href="appConfig.providerDetails.social.facebook"
              target="_blank"
              rel="noopener"
              aria-label="facebook link"
            >
              <img src="@/assets/icons/newfb.svg" alt="facebook_img" />
            </a>
          </li>
          <li>
            <a
              :href="appConfig.providerDetails.social.instagram"
              target="_blank"
              rel="noopener"
              aria-label="instagram link"
            >
              <img src="@/assets/icons/newinsta.svg" alt="instagram_img" />
            </a>
          </li>
          <li>
            <a
              :href="appConfig.providerDetails.social.twitter"
              target="_blank"
              rel="noopener"
              aria-label="twitter link"
            >
              <img src="@/assets/icons/newtwitter.svg" alt="twitter_img" />
            </a>
          </li>
          <li>
            <a
              :href="appConfig.providerDetails.social.youtube"
              target="_blank"
              rel="noopener"
              aria-label="pinterest link"
            >
              <img src="@/assets/icons/youtube.svg" alt="youtube" />
            </a>
          </li>
        </ul>
      </div> -->
    </div>
  </Popup>
</template>

<script>
import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import ClipboardJS from "clipboard";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import common from "@/mixins/common.js";
import { _providerId } from "@/provider-config.js";
import { mapGetters } from "vuex";

import { showSnackBar } from "@/utilities.js";

export default {
  props: {
    closePopup: {
      type: Function,
    },
  },
  data() {
    return {
      width: "40%",
      margin: "15% auto",
      radiusType: "full",
      medium: null,
      link: null,
      linkTwo: "https://www.ultrajhakaas.com",
      twitterlink: null,
      facebooklink: null,
      deeplink: null,
      clipboard: undefined,
      twitterShareUrl: null,
      projectName: null,
      pricemodel: null,
      availabilities: [],
      providerUniqueId: _providerId,
      copiedText: false,
    };
  },
  watch: {
    twitterlink(val) {
      if (val) {
        this.sendToTwitter();
      }
    },
    facebooklink(val) {
      if (val) {
        this.sendToFacebook();
      }
    },
  },
  created() {
    this.createLink();

    this.initTwitterUrl();
    window.dispatchEvent(window.facebookLoaded);
  },
  mounted() {
    setTimeout(() => {
      this.$refs.link.disabled = true;
      let dom = document.getElementById("popup-close-icon");
      dom.style.opacity = 1;
    }, 100);
  },
  computed: {
    ...mapGetters(["appConfig", "getRtl"]),
  },
  methods: {
    createBranchLink(medium) {
      let self = this;
      let linkData = {
        campaign: "App Share",
        channel: medium,
        stage: "new user",
        alias: "",
        data: {
          $og_title:
            "Hey, check out this app To get unlimited entertainmentApp",
          custom_bool: true,
          custom_int: Date.now(),
        },
      };
      branch.link(linkData, function (err, link) {
        // console.log("linkData", link);
        if (medium === "sharing") {
          self.link = link;
        } else if (medium === "twitter") {
          self.twitterlink = link;
        } else if (medium === "facebook") {
          self.facebooklink = link;
        }
      });
    },
    createLink() {
      this.createBranchLink("sharing");
      let self = this;

      if (this.clipboard) {
        this.clipboard.destroy();
      }

      // this.deeplink = `${window.location.host}/content?contentid=${this.content.objectid}`;

      this.clipboard = new ClipboardJS(".copy-btn");
      this.clipboard.on("success", function (e) {
        // Show the snackbar message
        // snackbar.show({ text: "Link Copied", pos: "bottom-center" });
        showSnackBar(this.$t("Link Copied"),this.$t('DISMISS'));
        e.clearSelection();
      });
      this.clipboard.on("error", function (e) {
        console.error("Action:", e.action);
      });
    },
    copyText() {
      this.copiedText = true;
      setTimeout(() => {
        this.copiedText = false;
      }, 2000);
    },
    twitterShare() {
      console.log("kjhghj");
    },
    initTwitterUrl() {
      this.createBranchLink("twitter");
    },
    sendToTwitter() {
      console.log("twitter link", this.twitterlink);
      this.twitterShareUrl =
        "https://twitter.com/share?url=" + decodeURIComponent(this.twitterlink);
    },
    shareThroughFacebook() {
      // Get the URL to be shared
      this.createBranchLink("facebook");
    },
    sendToFacebook() {
      let link = this.facebooklink;

      // Get the title to be shown on the feed
      let title = `Hey, check out this app To get unlimited entertainmentApp`;

      // Initialize Share
      window.FB.ui(
        {
          method: "share_open_graph",
          action_type: "og.shares",
          action_properties: JSON.stringify({
            object: {
              "og:url": link,
              "og:title": title,
            },
          }),
        },
        (response) => {
          console.log("[shareFbCB response]", response);
        }
      );
    },
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
  },
  mixins: [googleAnalytics, cleverTap, common],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./AppReferal.scss"
</style>